export function envDomainSuffix(url: string) {
  return url;
}
export function messageScript(): { url: string, integrity: string, msgEnv: string } {
  return {
    url: 'https://assets.graphisoft.com/scripts/gsMessage/gsMessage.js',
    integrity: 'sha384-5qB2tTbm4OLvyCuRSNahJXJP2H3YFeCoUIuZxbtZhjfJ4D9J378h800ndRldWj53',
    msgEnv: 'production',
  };
}
